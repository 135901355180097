import React, {useState} from 'react'
import Button from '@material-ui/core/Button'

import { Task } from '../models/Task'

const Tasks = () => {
    const [tasks, setTasks] = useState<Task[]>([]);

    function handleLoadTasks(){
        setTasks([
            {
                name: "Ring doctor",
            }
        ])
    }

    return <div>
        {tasks.map((task) => <div></div>)}

        <div>
            <Button variant="contained" color="primary">Create Task</Button>
        </div>
    </div>
}

export default Tasks;