import React from 'react';
import { BrowserRouter as Router, Switch, Link, Route } from 'react-router-dom'
import './App.css';
import Tasks from './application/Tasks';
import Singin from './application/Signin';
import SignIn from './application/Signin';

const App = () => {


  return (
    <div className="App">
      <Router>
      <header>
      <Link to="/">Home</Link>
      <Link to="/signin">Sign in</Link>
      </header>
      <div>
        <Switch>
          <Route path="/signin">
            <SignIn></SignIn>
          </Route>
          <Route path="/" >
            <Tasks />
          </Route>
        </Switch>
      </div>
      <footer></footer>
      </Router>
    </div>
  );
}

export default App;
